import {
  Flex,
  IconButton,
  Image,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { API_ROUTES, ROOT_API } from "utils/constant";
import { axiosPost } from "utils/api";

function WalletQRCodeRow({ row, isLast, fetchData, handleUpdateQRCode }) {
  const history = useHistory();
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();

  const handleDeleteClick = async () => {
    if (window.confirm("Bạn có chắc chắn muốn xoá QR này không?")) {
      const data = {
        qrCodeId: row._id,
      };

      try {
        const apiUrl = `${ROOT_API}${API_ROUTES.DELETE_WALLET_QR_CODE}`;
        const response = await axiosPost(apiUrl, data);

        if (response?.data?.code == 0) {
          toast({
            title: "Xoá thành công",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Xoá thất bại",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.standard}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.code}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.isUsed ? "Đang sử dụng" : "Không sử dụng"}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex>
            <IconButton
              p="0px"
              bg="transparent"
              color="blue.400"
              variant="no-effects"
              onClick={() => {
                handleUpdateQRCode(row);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              p="0px"
              bg="transparent"
              color="red.400"
              variant="no-effects"
              onClick={handleDeleteClick}
            >
              <DeleteIcon />
            </IconButton>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default WalletQRCodeRow;
