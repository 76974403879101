import { useEffect, useState } from "react";
import { Button, Flex, Grid, GridItem, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { QRCodeSVG } from "qrcode.react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import InputController from "components/Form/InputController";
import SelectController from "components/Form/SelectController";
import NumericInputController from "components/Form/NumericInputController";
import { API_ROUTES, ROOT_API, ValidateMessage } from "utils/constant";

const schema = yup.object().shape({
  accountNumber: yup.string().required(ValidateMessage.required),
  bank: yup.object().nullable().required(ValidateMessage.required),
  amount: yup.string().nullable().required(ValidateMessage.required),
  description: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9\ ]*$/, "Ghi chú không hợp lệ"),
});

export default function BankQRCode() {
  const toast = useToast();

  const [qrCode, setQRCode] = useState(null);
  const [bankOptions, setBankOptions] = useState([]);

  const [{ data: bankData }] = useAxios(
    {
      url: `https://e-sim.go9.me${API_ROUTES.BANK}`,
    },
    {
      useCache: false,
    }
  );
  const [{ loading: isCreating }, createQRCodeAPI] = useAxios(
    {
      method: "post",
      url: `${ROOT_API}${API_ROUTES.CREATE_BANK_QR_CODE}`,
    },
    { manual: true }
  );

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: "",
      accountNumber: "",
      bank: undefined,
      description: "",
    },
  });

  useEffect(() => {
    if (bankData?.data) {
      setBankOptions(
        bankData?.data?.map((item) => ({
          label: `${item.name} - ${item.shortName}`,
          value: item.bin,
        }))
      );
    }
  }, [bankData?.data]);

  const onSubmit = (values) => {
    createQRCodeAPI({ data: { ...values, bin: values?.bank?.value } })
      .then((response) => {
        console.log(response);
        setQRCode(response?.data?.data);
        toast({
          title: "Tạo QR Code thành công",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) =>
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "QR Code thất bại",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      );
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", lg: "100px" }}>
      <Card px="16px" mb="24px" bg="#fff">
        <CardBody overflowX="auto" p="0 5px">
          <Grid
            py="8px"
            templateColumns={{ base: "1fr", md: "repeat(6, 1fr)" }}
            gap={4}
            minH="360px"
          >
            <GridItem colSpan={{ base: 1, md: 3 }}>
              <SelectController
                label="Ngân hàng"
                name="bank"
                placeholder="Chọn"
                control={control}
                options={bankOptions}
                isRequired
                onChange={() => {
                  setQRCode(null);
                }}
              />
              <InputController
                control={control}
                name="accountNumber"
                label="Số tài khoản ngân hàng"
                isRequired
                styleContainer={{ pt: "4" }}
              />
              <NumericInputController
                control={control}
                name="amount"
                label="Số tiền"
                isRequired
                styleContainer={{ pt: "4" }}
              />
              <InputController
                type="textarea"
                control={control}
                name="description"
                label="Ghi chú"
                styleContainer={{ pt: "4" }}
              />
              <Flex gap={2} direction={{ base: "row", md: "row" }} mt={4}>
                <Button
                  width={{ base: "50%", md: "auto" }}
                  maxH="40px"
                  onClick={() => {
                    reset();
                    setQRCode(null);
                  }}
                >
                  Cài lại
                </Button>
                <Button
                  width={{ base: "50%", md: "auto" }}
                  fontSize="md"
                  fontWeight="bold"
                  variant="primary"
                  isLoading={isCreating}
                  onClick={handleSubmit(onSubmit)}
                >
                  Tạo QR Code
                </Button>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 3 }}>
              <Flex justifyContent="center" alignItems="center" h="full">
                {qrCode && (
                  <QRCodeSVG
                    id="qrCode"
                    value={qrCode}
                    size={290}
                    level={"H"}
                  />
                )}
              </Flex>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </Flex>
  );
}
