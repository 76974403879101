import {
  Button,
  Flex,
  Switch,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import { ROOT_API, API_ROUTES, ModalType } from "utils/constant";
import { axiosPost } from "utils/api";
import moment from "moment";
import { formatNumber } from "utils/helpers";

function HistoryRow({ row, isLast, refetchData, handelClickRow }) {
  const history = useHistory();
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();

  const [, usingQRCodeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.USING_QR_CODE,
    },
    { manual: true }
  );

  const handleSuccess = (message) => {
    refetchData?.();
    toast({
      title: `${message} Successfully`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleError = (error, message) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${message} Fail`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleDeleteClick = async () => {
    if (window.confirm("Are you sure to delete this season?")) {
      const data = {
        id: row._id,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.DELETE_SEASON,
          data
        );
        if (response?.data?.code === 0) {
          toast({
            title: "Delete League Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          refetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete League Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const handleChangeStatus = async () => {
    if (window.confirm("Bạn có chắc chắn muốn sử dụng QR Code này không?")) {
      usingQRCodeApi({ data: { id: row._id } })
        .then(() => handleSuccess("Sử dụng QR Code"))
        .catch((error) => {
          handleError(error, "Sử dụng QR Code");
        });
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.transactionId?.vendor_order_no}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {formatNumber(row?.preBalance || 0)}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {formatNumber(row?.amountAfterFee || 0)}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {formatNumber(row?.currBalance || 0)}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {moment(row?.updatedAt).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default HistoryRow;
