import { useRef, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import isEmpty from "lodash/isEmpty";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Pagination from "components/Pagination/Pagination";
import { useUserState } from "context/UserContext";
import { API_ROUTES, ROOT_API } from "utils/constant";
import useAxios from "axios-hooks";
import BankRow from "./components/Row";

export default function Bank() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [searchTitle, setSearchTitle] = useState("");

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `https://e-sim.go9.me${API_ROUTES.BANK}`,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  const onReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 100,
    });
    setSearchTitle("");
  };

  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px", lg: "100px" }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={"space-between"}>
              <Flex direction={"column"}>
                <Flex direction="column" gap={"30px"}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Quản lý ngân hàng
                  </Text>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"end"}
                  gap={"20px"}
                  my={"20px"}
                >
                  <Stack>
                    <Flex
                      gap={"20px"}
                      flexDirection={"column"}
                      flexWrap={{ base: "wrap", md: "nowrap" }}
                    >
                      <Flex
                        alignItems={"center"}
                        justifyContent={"start"}
                        flexWrap="wrap"
                      >
                        <FormControl
                          w="300px"
                          display="flex"
                          alignItems="center"
                        >
                          <FormLabel m="0" pr="10px" minW="120px">
                            Tìm kiếm:
                          </FormLabel>
                          <Input
                            value={searchTitle}
                            onChange={(e) => setSearchTitle(e.target.value)}
                          />
                        </FormControl>
                        <Button
                          variant="primary"
                          maxH="30px"
                          m="10px"
                          alignSelf={"end"}
                          onClick={onReset}
                        >
                          Cài lại
                        </Button>
                        <Button
                          variant="primary"
                          maxH="30px"
                          m="10px"
                          px={5}
                          alignSelf={"end"}
                          onClick={onFilter}
                        >
                          Tìm kiếm
                        </Button>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow="auto" minH="300px">
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th borderColor={borderColor} color="gray.400">
                      Ảnh
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Tên ngân hàng
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Bin
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.length ? (
                    data?.data?.map((row, index, arr) => {
                      return (
                        <BankRow
                          row={row}
                          isLast={index === arr.length - 1 ? true : false}
                          key={index}
                        />
                      );
                    })
                  ) : (
                    <Tr>
                      <Td
                        textAlign="center"
                        fontSize={14}
                        colSpan={3}
                        border="none"
                      >
                        Chưa có dữ liệu
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Stack>
            <Flex justifyContent={"flex-end"}>
              {!isEmpty(data?.data) && (
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
}
