import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  FormControl,
  FormLabel,
  Box,
  Flex,
  Avatar,
  Text,
  Image,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";
import { isObject, pick } from "lodash";
import InputController from "components/Form/InputController";
import {
  ValidateMessage,
  ROOT_API,
  API_ROUTES,
  ModalType,
  NetworkOption,
  FileImageValid,
} from "utils/constant";
import SelectController from "components/Form/SelectController";

const schema = yup.object().shape({
  account: yup.string().required(ValidateMessage.required),
  standard: yup.object().nullable().required(ValidateMessage.required),
  code: yup.string().nullable().required(ValidateMessage.required),
});

const AddQRCodeDialog = ({ isOpen, qrCodeDetail, onClose, refetchData }) => {
  const cancelRef = useRef();
  const qrCodeFileRef = useRef();
  const toast = useToast();
  const params = useParams();
  const { id } = params || {};
  const [fileSelected, setFileSelected] = useState({
    qrCodeFile: null,
  });
  const [error, setError] = useState({
    qrCodeFile: null,
  });
  const [{ loading: createLoading }, uploadQRCodeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPLOAD_QR_CODE_CUSTOMER,
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updateQRCodeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPDATE_QR_CODE_CUSTOMER,
    },
    { manual: true }
  );

  useEffect(
    () => () => {
      reset();
    },
    []
  );

  useEffect(() => {
    reset({
      ...pick(qrCodeDetail, ["account", "code"]),
      standard: NetworkOption.find(
        (item) => item.value === qrCodeDetail?.standard
      ),
    });
    setFileSelected({
      qrCodeFile: {
        filePreview: qrCodeDetail?.qrCodeUrl
          ? process.env.REACT_APP_API_HOST + "/" + qrCodeDetail?.qrCodeUrl
          : null,
      },
    });
  }, [qrCodeDetail]);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      account: "",
      code: "",
      standard: undefined,
    },
  });

  const handleSuccess = () => {
    refetchData?.();
    toast({
      title: `${qrCodeDetail?._id ? "Cập nhập" : "Tạo"} QR Code thành công`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    onClose?.(ModalType.Add);
  };

  const handleError = (error) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${qrCodeDetail?._id ? "Cập nhập" : "Tạo"} QR Code thất bại`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = (valuesForm) => {
    if (!fileSelected.qrCodeFile?.filePreview) {
      setError({
        qrCodeFile: "Vui lòng tải QR Code ",
      });
      return;
    }

    const formData = new FormData();

    Object.keys(valuesForm).map((key) => {
      formData.append(
        key,
        isObject(valuesForm[key]) ? valuesForm[key]?.value : valuesForm[key]
      );
    });
    !!fileSelected.qrCodeFile?.file &&
      formData.append("qrCodeFile", fileSelected.qrCodeFile?.file);
    !!qrCodeDetail?._id && formData.append("id", qrCodeDetail?._id);

    if (qrCodeDetail) {
      updateQRCodeApi({ data: formData })
        .then(() => handleSuccess())
        .catch((error) => handleError(error));
      return;
    }

    uploadQRCodeApi({ data: formData })
      .then(() => handleSuccess())
      .catch((error) => handleError(error));
  };

  const handleFileSelect = (fieldName, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name?.split(".")?.pop();

      if (FileImageValid.includes(extensionFile)) {
        setFileSelected((prev) => ({
          ...prev,
          [fieldName]: { file: fileSelected, filePreview },
        }));
      } else {
        setFileSelected((prev) => ({
          ...prev,
          [fieldName]: null,
        }));
        handleErrorFile(
          fieldName,
          "QR Code chỉ hỗ trợ các dạng .png, .jpeg, .jpg"
        );
      }
    }
  };

  const handleErrorFile = (fieldName, value) => {
    setError((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose?.(ModalType.Add);
      }}
      isOpen={isOpen}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{`${
          qrCodeDetail?._id ? "Cập nhập" : "Tạo"
        } QR Code`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <InputController
              control={control}
              name="account"
              label="Tên"
              isRequired
            />
            <SelectController
              control={control}
              name="standard"
              label="Network"
              isRequired
              options={NetworkOption}
              styleContainer={{ pt: "4" }}
            />
            <InputController
              control={control}
              name="code"
              label="Địa chỉ"
              isRequired
              styleContainer={{ pt: "4" }}
            />
            <FormControl pt={4} isRequired>
              <FormLabel minW="150px">QR Code</FormLabel>
              <Box alignItems="center">
                <Flex alignItems="center" minH="48px">
                  <input
                    accept="image/*"
                    type="file"
                    hidden
                    ref={qrCodeFileRef}
                    onChange={(e) => {
                      handleFileSelect("qrCodeFile", e);
                    }}
                  />
                  {!!fileSelected.qrCodeFile?.filePreview ? (
                    <Flex
                      w="100%"
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                    >
                      <Image
                        width={300}
                        height={300}
                        src={fileSelected.qrCodeFile?.filePreview}
                      />
                      <Text
                        ml={2}
                        fontSize="12px"
                        color="blue.400"
                        cursor="pointer"
                        onClick={() => {
                          handleErrorFile("qrCodeFile", null);
                          qrCodeFileRef?.current?.click();
                        }}
                      >
                        Thay đổi
                      </Text>
                    </Flex>
                  ) : (
                    <Button
                      variant="primary"
                      maxH="30px"
                      onClick={() => {
                        handleErrorFile("qrCodeFile", null);
                        qrCodeFileRef?.current?.click();
                      }}
                    >
                      Tải lên file
                    </Button>
                  )}
                </Flex>
                {!!error.homeLogo && (
                  <Text pt={1} color={"red.500"} fontSize="13px">
                    {error.homeLogo}
                  </Text>
                )}
              </Box>
            </FormControl>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose?.(ModalType.Add);
            }}
          >
            Hủy
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            isLoading={createLoading || updateLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {qrCodeDetail ? "Cập nhập" : "Tạo"}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AddQRCodeDialog;
