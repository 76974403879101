import React, { useMemo, useRef, useState } from "react";
import {
  Flex,
  Table,
  Button,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Input,
  InputRightElement,
  InputGroup,
  useToast,
  Box,
  Td,
  Stack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import { ArrowBackIcon, SearchIcon } from "@chakra-ui/icons";
import { TablePagination } from "@trendmicro/react-paginations";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { ModalType } from "utils/constant";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { initialFilter } from "utils/constant";
import Loading from "components/Layout/Loading";
import HistoryRow from "./components/Row";
import { downloadFile } from "utils/helpers";
import moment from "moment";
import DatePicker from "components/DatePicker/DatePicker";
import InputSearch from "components/InputSearch/InputSearch";

const GuestHistory = () => {
  const refSearchButton = useRef(null);
  const params = useParams();
  const history = useHistory();
  const { id } = params || {};
  const [filter, setFilter] = useState(initialFilter);
  const [searchValue, setSearchValue] = useState("");
  const [qrCodeDetail, setQRCodeDetail] = useState(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${ROOT_API}${API_ROUTES.GUEST_TRANSACTION}`,
      params: filter,
    },
    {
      useCache: false,
    }
  );
  const [{ loading: exportLoading }, exportHistoryApi] = useAxios(
    {
      method: "get",
      url: `${ROOT_API}${API_ROUTES.GUEST_TRANSACTION}`,
      responseType: "arraybuffer",
      params: { isExport: "true" },
    },
    { manual: true }
  );

  const onExportHistory = () => {
    exportHistoryApi()
      .then((response) => {
        downloadFile(response?.data, "history");
        toast({
          title: "Export lịch sử thành công",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Export lịch sử không thành công",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onChangeDate = (type) => (date) => {
    if (type === "startDate") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      start: startDate ? moment(startDate).toISOString() : "",
      end: endDate ? moment(endDate).endOf("days").toISOString() : "",
      searchKeyword,
    });
  };

  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword("");
  };

  const onChangeSearch = (event) => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Flex alignItems="center">
              <Text ml={2} fontSize="xl" color={textColor} fontWeight="bold">
                Lịch sử giao dịch
              </Text>
            </Flex>
          </CardHeader>
          <CardBody pb={4}>
            <FormControl display="flex" alignItems="center" mb="12px">
              <FormLabel m="0" pr="10px" minW="120px">
                Tìm kiếm:
              </FormLabel>
              <InputSearch
                value={searchKeyword}
                onChange={onChangeSearch}
                onClearSearch={onClearSearch}
              />
            </FormControl>
            <Flex>
              <FormControl
                display="flex"
                alignItems="center"
                maxW="300px"
                mr="12px"
              >
                <FormLabel m="0" pr="10px" minW="120px">
                  Ngày bắt đầu:
                </FormLabel>
                <DatePicker
                  selectedDate={startDate}
                  onChange={(date) => onChangeDate("startDate")(date)}
                />
              </FormControl>
              <FormControl
                display="flex"
                alignItems="center"
                maxW="300px"
                mr="12px"
              >
                <FormLabel m="0" pr="10px" minW="120px">
                  Ngày kết thúc:
                </FormLabel>
                <DatePicker
                  selectedDate={endDate}
                  minDate={startDate}
                  onChange={(date) => onChangeDate("endDate")(date)}
                />
              </FormControl>
              <Button
                variant="primary"
                maxH="30px"
                m="10px"
                onClick={() => {
                  setFilter(initialFilter);
                  setEndDate("");
                  setStartDate("");
                }}
              >
                Cài lại
              </Button>
              <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                Lọc
              </Button>
              <Button
                variant="primary"
                maxH="30px"
                m="10px"
                isLoading={exportLoading}
                onClick={onExportHistory}
              >
                Export
              </Button>
            </Flex>
            <Flex mb={3} columnGap={2} justifyContent={"space-between"}>
              <Box>
                {/* <Button
                  variant="primary"
                  maxH="30px"
                  m="10px"
                  onClick={onOpenAddModal}
                >
                  Thêm QR Code
                </Button> */}
              </Box>
            </Flex>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Stack overflow="auto" minH="300px">
                  <Table variant="simple" color={textColor}>
                    <Thead>
                      <Tr my=".8rem" pl="0px" color="gray.400">
                        <Th borderColor={borderColor} color="gray.400">
                          Order
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Merchant order
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Thực nhận U
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Phí
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Thực nhận vào ví
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Số tiền VNĐ
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Tỷ giá
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Trạng thái
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Thời gian
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.data?.length ? (
                        data?.data?.map((row, index, arr) => {
                          return (
                            <HistoryRow
                              row={row}
                              isLast={index === arr.length - 1 ? true : false}
                              key={index}
                            />
                          );
                        })
                      ) : (
                        <Tr>
                          <Td
                            textAlign="center"
                            fontSize={14}
                            colSpan={7}
                            border="none"
                          >
                            Chưa có dữ liệu
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </Stack>

                {data?.data?.length > 0 && (
                  <Flex justifyContent={"flex-end"}>
                    <TablePagination
                      type="full"
                      page={data?.pagination?.page}
                      pageLength={data?.pagination?.pageSize}
                      totalRecords={data?.pagination?.count}
                      onPageChange={({ page, pageLength }) => {
                        console.log(page);
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      }}
                      prevPageRenderer={() => (
                        <i className="fa fa-angle-left" />
                      )}
                      nextPageRenderer={() => (
                        <i className="fa fa-angle-right" />
                      )}
                    />
                  </Flex>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default GuestHistory;
