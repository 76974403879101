// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Assets
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import InputController from "components/Form/InputController";
import { useUserState } from "context/UserContext";
import React, { useState } from "react";
import {
  FaLock,
} from "react-icons/fa";
import { PASSWORD_REGEX, ValidateMessage } from "utils/constant";
import { axiosPost } from "utils/api";
import { API_ROUTES, ROOT_API } from "utils/constant";
import { useForm } from "react-hook-form";

const changePassApi = ROOT_API + API_ROUTES.CHANGE_PASSWORD;
const defaultValue = {
  password: "",
  newPassword: "",
  passwordConf: "",
};
const defaultValueShow = {
  password: false,
  newPassword: false,
  passwordConf: false,
};
function Profile() {
  const { colorMode } = useColorMode();
  const [changePass, setChangePass] = useState(defaultValue);
  const [show, setShow] = useState(defaultValueShow);
  const schema = yup.object().shape({
    password: yup.string().required(ValidateMessage.required),
    newPassword: yup
      .string()
      .matches(
        PASSWORD_REGEX,
        "Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt"
      )
      .required(ValidateMessage.required),
    passwordConf: yup
      .string()
      .required(ValidateMessage.required)
      .matches(
        PASSWORD_REGEX,
        "Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt"
      )
      .test({
        name: "newPassword",
        exclusive: false,
        message: "Xác nhận mật khảu phải trùng với mật khẩu mới",
        test: function (value) {
          if (this.parent?.newPassword !== value) {
            return false;
          }

          return true;
        },
      }),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");
  const { isAuthenticated, userInfo } = useUserState();
  const toast = useToast();
  const changePassword = async (data) => {
    // return;
    try {
      const response = await axiosPost(changePassApi, data);
      if (response?.data?.code == 0) {
        toast({
          title: "Đổi mật khẩu thành công",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reset(defaultValue);
      }
    } catch (error) {
      console.log(error, "error");
      toast({
        title:
          ValidateMessage[error?.response?.data?.msg] ||
          error?.response?.data?.errors?.errors[0]?.msg ||
          "Đổi mật khẩu thất bại",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", lg: "100px" }}>
      {!!isAuthenticated ? (
        <>
          <Flex
            direction={{ sm: "column", md: "row" }}
            mb="24px"
            maxH="330px"
            justifyContent={{ sm: "center", md: "space-between" }}
            align="center"
            backdropFilter="blur(21px)"
            boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
            border="1.5px solid"
            borderColor={borderProfileColor}
            bg={bgProfile}
            p="24px"
            borderRadius="20px"
          >
            <Flex
              align="center"
              mb={{ sm: "10px", md: "0px" }}
              direction={{ sm: "column", md: "row" }}
              w={{ sm: "100%" }}
              textAlign={{ sm: "center", md: "start" }}
            >
              <Avatar
                me={{ md: "22px" }}
                w="80px"
                h="80px"
                borderRadius="15px"
              />
              <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
                <Text
                  fontSize={{ sm: "lg", lg: "xl" }}
                  color={textColor}
                  fontWeight="bold"
                  ms={{ sm: "8px", md: "0px" }}
                >
                  {userInfo?.username}
                </Text>
                <Text
                  fontSize={{ sm: "sm", md: "md" }}
                  color={emailColor}
                  fontWeight="semibold"
                >
                  {userInfo?.email}
                </Text>
              </Flex>
            </Flex>
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            ></Flex>
          </Flex>
          <Card p="16px" my="24px">
            <CardHeader p="12px 5px" mb="12px">
              <Flex direction="column">
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Đổi mật khẩu
                </Text>
              </Flex>
            </CardHeader>
            <CardBody px="50px">
              <form>
                <Flex direction="column">
                  <FormControl mb={3}>
                    <InputGroup size="md">
                      <InputController
                        type={show.password ? "text" : "password"}
                        control={control}
                        name="password"
                        label="Mật khẩu hiện tại"
                        isRequired
                      />
                      <InputRightElement width="4.5rem" top="auto" bottom={0}>
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() =>
                            setShow({
                              ...show,
                              password: !show.password,
                            })
                          }
                        >
                          {show.password ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl mb={3}>
                    <InputGroup size="md">
                      <InputController
                        type={show.newPassword ? "text" : "password"}
                        control={control}
                        name="newPassword"
                        label="Mật khẩu mới"
                        isRequired
                      />
                      <InputRightElement width="4.5rem" top="auto" bottom={0}>
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() =>
                            setShow({
                              ...show,
                              newPassword: !show.newPassword,
                            })
                          }
                        >
                          {show.newPassword ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl mb={3}>
                    <InputGroup size="md">
                      <InputController
                        type={show.passwordConf ? "text" : "password"}
                        control={control}
                        name="passwordConf"
                        label="Xác nhận mật khẩu"
                        isRequired
                      />
                      <InputRightElement width="4.5rem" top="auto" bottom={0}>
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() =>
                            setShow({
                              ...show,
                              passwordConf: !show.passwordConf,
                            })
                          }
                        >
                          {show.passwordConf ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </Flex>
                <Button p="0px" bg="transparent" variant="no-effects">
                  <Flex
                    align="center"
                    w={{ sm: "100%", lg: "135px" }}
                    bg={colorMode === "dark" ? "navy.900" : "#fff"}
                    borderRadius="8px"
                    justifyContent="center"
                    py="10px"
                    boxShadow="2px 2px 5.5px rgba(0, 0, 0, 0.06)"
                    cursor="pointer"
                    onClick={handleSubmit(changePassword)}
                  >
                    <Icon color={textColor} as={FaLock} me="6px" />
                    <Text fontSize="xs" color={textColor} fontWeight="bold">
                      Đổi mật khẩu
                    </Text>
                  </Flex>
                </Button>
              </form>
            </CardBody>
          </Card>
        </>
      ) : (
        ""
      )}
    </Flex>
  );
}

export default Profile;
