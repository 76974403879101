import {
  Button,
  Flex,
  Switch,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import { ROOT_API, API_ROUTES, ModalType } from "utils/constant";
import { axiosPost } from "utils/api";

function QRCodeRow({ row, isLast, refetchData, handelClickRow }) {
  const history = useHistory();
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();

  const [, usingQRCodeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.USING_QR_CODE,
    },
    { manual: true }
  );
  const [, deleteQRCodeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.DELETE_QR_CODE_CUSTOMER,
    },
    { manual: true }
  );

  const handleSuccess = (message) => {
    refetchData?.();
    toast({
      title: `${message} Successfully`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleError = (error, message) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${message} Fail`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleDeleteClick = async () => {
    if (window.confirm("Bạn có chắc chắn muốn Xóa QR Code này không?")) {
      deleteQRCodeApi({ data: { qrCodeId: row._id } })
        .then(() => handleSuccess("Xóa QR Code"))
        .catch((error) => {
          handleError(error, "Xóa QR Code");
        });
    }
  };

  const handleChangeStatus = async () => {
    if (window.confirm("Bạn có chắc chắn muốn sử dụng QR Code này không?")) {
      usingQRCodeApi({ data: { id: row._id } })
        .then(() => handleSuccess("Sử dụng QR Code"))
        .catch((error) => {
          handleError(error, "Sử dụng QR Code");
        });
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.account}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.standard}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.code}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex gap={2}>
            <Switch
              size="md"
              isChecked={row.isUsed}
              onChange={(e) => handleChangeStatus(e.target.checked)}
            />
            {row.isUsed && (
              <Text color="green.400" fontSize={14}>
                Đang sử dụng
              </Text>
            )}
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Button
            p="0px"
            bg="transparent"
            variant="no-effects"
            onClick={() => {
              handelClickRow(row, ModalType.Add);
            }}
            mr={2}
          >
            <Text
              fontSize="md"
              color="gray.400"
              fontWeight="bold"
              cursor="pointer"
            >
              <EditIcon />
            </Text>
          </Button>
          <Button
            p="0px"
            bg="transparent"
            variant="no-effects"
            onClick={handleDeleteClick}
          >
            <Text fontSize="md" color="red" fontWeight="bold" cursor="pointer">
              <DeleteIcon />
            </Text>
          </Button>
        </Td>
      </Tr>
    </>
  );
}

export default QRCodeRow;
