import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Flex,
  useColorModeValue,
  Text,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import BarChart from "components/Charts/BarChart";
import { formatDate, getInitFilerChart } from "utils/helpers";
import DatePicker from "components/DatePicker/DatePicker";
import moment from "moment";
import { isEmpty } from "lodash";

const Week = [0, 1, 2, 3, 4, 5, 6];
const BarChartOptions = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  xaxis: {
    categories: [],
    labels: {
      style: {
        colors: "#A0AEC0",
        fontSize: "12px",
      },
    },
    // show: true,
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    // show: true,
    color: "#A0AEC0",
    labels: {
      //   show: true,
      style: {
        colors: "#A0AEC0",
        fontSize: "14px",
      },
    },
  },
  fill: {
    colors: "#9ad0f5",
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
      borderRadius: 0,
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
        },
      },
    },
  ],
};

const initFiler = getInitFilerChart();

const SummariesCoinChart = ({
  title = "Thống kê",
  labelChart = "Số liệu",
  dataChart,
  redirectUrl,
  getNewData,
}) => {
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const [filter, setFilter] = useState({ ...initFiler });
  const [categories, setCategories] = useState([]);

  const barChartData = useMemo(() => {
    const qty = dataChart?.length
      ? [...dataChart]?.reverse().map((item) => item?.total || 0)
      : [];
    const total = qty?.reduce((a, b) => a + b, 0);
    const data = [
      {
        name: labelChart,
        data: qty,
      },
    ];

    return { data, total };
  }, [dataChart, labelChart]);

  useEffect(() => {
    const categoriesTmp = dataChart?.length
      ? [...dataChart]?.reverse()?.map((item) => item?._id?.time)
      : [];

    setCategories(categoriesTmp);

    if (!isEmpty(categoriesTmp)) {
      setFilter({
        startDate: new Date(formatDate(moment(categoriesTmp[0]))),
        endDate: new Date(
          formatDate(moment(categoriesTmp[categoriesTmp.length]))
        ),
      });
    }
  }, [dataChart]);

  const barChartOptions = useMemo(
    () => ({
      ...BarChartOptions,
      chart: {
        ...BarChartOptions.chart,
      },
      xaxis: {
        ...BarChartOptions.xaxis,
        categories,
      },
      yaxis: {
        ...BarChartOptions.yaxis,
      },
    }),
    [categories, barChartData?.total]
  );

  const onChangeDate = (type) => (date) => {
    setFilter((prev) => ({
      ...prev,
      ...(type === "startDate" && {
        endDate: new Date(formatDate(moment(date).add(6, "days"))),
      }),
      [type]: date,
    }));
  };

  const onFilter = () => {
    getNewData(filter.startDate, filter.endDate);
    setCategories(
      [...Week]
        .slice(
          0,
          moment(filter.endDate).diff(moment(filter.startDate), "days") + 1
        )
        .map((item) =>
          formatDate(moment(filter.startDate).add(item, "days"), "DD-MM-YYYY")
        )
    );
  };

  return (
    <Flex direction="column">
      <Text
        color={textColor}
        fontSize="lg"
        fontWeight="bold"
        textAlign="center"
      >
        {title}
      </Text>
      <Flex mt="14px" pl="22px" flexWrap="wrap">
        <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
          <FormLabel m="0" pr="10px">
            Từ ngày:
          </FormLabel>
          <DatePicker
            selectedDate={filter.startDate}
            onChange={(date) => onChangeDate("startDate")(date)}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
          <FormLabel m="0" pr="10px">
            Đến ngày:
          </FormLabel>
          <DatePicker
            selectedDate={filter.endDate}
            minDate={filter.startDate}
            maxDate={
              new Date(formatDate(moment(filter.startDate).add(6, "days")))
            }
            onChange={(date) => onChangeDate("endDate")(date)}
          />
        </FormControl>
        <Button variant="primary" fontSize="14px" onClick={onFilter}>
          Lọc
        </Button>
      </Flex>
      <Box minH="300px">
        <BarChart
          chartData={barChartData.data}
          chartOptions={barChartOptions}
        />
      </Box>
      {redirectUrl && (
        <Flex mt="12px" justifyContent="center">
          <Button
            bg="#3182ce"
            color="#fff"
            px="10px"
            maxH="40px"
            alignSelf={"end"}
            onClick={() => {
              history.push(redirectUrl);
            }}
          >
            Xem thêm
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default SummariesCoinChart;
