// import
import React, { Component } from "react";
import { FaUsers } from "react-icons/fa";
import { MdOutlineQrCode2, MdHistory } from "react-icons/md";
import { AiOutlineBank } from "react-icons/ai";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import User from "views/Dashboard/User/User";

import { StatsIcon, PersonIcon, HomeIcon } from "components/Icons/Icons";
import Member from "views/Dashboard/Member/Member";
import HistoryList from "views/Dashboard/History";
import { Roles } from "utils/constant";
import QRCode from "views/Dashboard/QRCode";
import GuestHistory from "views/Dashboard/GuestHistory";
import BalanceFluctuations from "views/Dashboard/BalanceFluctuations";
import Bank from "views/Dashboard/Bank";
import BankQRCode from "views/Dashboard/BankQRCode";
import Dashboard from "views/Dashboard/Dashboard";
import WalletQRCode from "views/Dashboard/WalletQRCode";

var dashRoutes = [
  {
    path: "/trang-chu",
    name: "Trang chủ",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/quan-tri",
    name: "Quản Trị",
    icon: <StatsIcon color="inherit" />,
    component: User,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/hoi-vien",
    name: "Hội Viên",
    icon: <MdHistory color="inherit" />,
    component: Member,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/ngan-hang",
    name: "Ngân hàng",
    icon: <AiOutlineBank color="inherit" />,
    component: Bank,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/bank-qr-code",
    name: "QR Code",
    icon: <MdOutlineQrCode2 color="inherit" size={16} />,
    component: BankQRCode,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/wallet-qr-code",
    name: "Wallet QR Code",
    icon: <MdOutlineQrCode2 color="inherit" size={16} />,
    component: WalletQRCode,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/giao-dich",
    name: "Giao dịch",
    icon: <StatsIcon color="inherit" />,
    component: HistoryList,
    layout: "/admin",
    role: [Roles.ADMIN],
  },

  // {
  //   path: "/qr-code",
  //   name: "QR Code",
  //   icon: <MdOutlineQrCode2 color="inherit" size={16} />,
  //   component: QRCode,
  //   layout: "/admin",
  //   role: [Roles.GUEST],
  // },
  {
    path: "/history",
    name: "Lịch sử giao dịch",
    icon: <MdHistory color="inherit" size={16} />,
    component: GuestHistory,
    layout: "/admin",
    role: [Roles.GUEST],
  },
  {
    path: "/balance-fluctuations",
    name: "Biến động số dư",
    icon: <MdHistory color="inherit" size={16} />,
    component: BalanceFluctuations,
    layout: "/admin",
    role: [Roles.GUEST],
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/thong-tin-ca-nhan",
        name: "Thông Tin Cá Nhân",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
        role: [Roles.ADMIN, Roles.GUEST],
      },
      {
        path: "/signin",
        name: "Sign In",
        component: SignIn,
        layout: "/auth",
        icon: <PersonIcon color="inherit" />,
        hidden: true,
      },
    ],
  },
];
export default dashRoutes;
