import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  API_ROUTES,
  ModalType,
  ROOT_API,
  ValidateMessage,
} from "utils/constant";
import useAxios from "axios-hooks";
import InputController from "components/Form/InputController";

const schema = yup.object().shape({
  code: yup.string().required(ValidateMessage.required),
});

export default function AddQRCodeModal({
  isOpen,
  qrCodeDetail,
  onClose,
  refetchData,
}) {
  const cancelRef = useRef();
  const toast = useToast();

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: "",
    },
  });

  useEffect(() => {
    if (qrCodeDetail) {
      setValue("code", qrCodeDetail?.code);
    }
  }, [qrCodeDetail]);

  const [{ loading: createLoading }, createQRCodeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CREATE_WALLET_QR_CODE,
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updateQRCodeApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPDATE_WALLET_QR_CODE,
    },
    { manual: true }
  );

  const handleSuccess = () => {
    refetchData?.();
    toast({
      title: `${qrCodeDetail?._id ? "Cập nhật" : "Tạo"} QR Code thành công`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    onClose?.(ModalType.Add);
  };

  const handleError = (error) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${qrCodeDetail?._id ? "Cập nhật" : "Tạo"} QR Code thất bại`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = (valuesForm) => {
    if (qrCodeDetail) {
      updateQRCodeApi({ data: { ...valuesForm, id: qrCodeDetail?._id } })
        .then(() => handleSuccess())
        .catch((error) => handleError(error));
      return;
    }

    createQRCodeApi({ data: valuesForm })
      .then(() => handleSuccess())
      .catch((error) => handleError(error));
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW={"450px"}>
        <AlertDialogHeader textTransform="uppercase">
          {qrCodeDetail ? "Cập nhật" : "Tạo"} QR Code
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <InputController
            control={control}
            name="code"
            label="Địa chỉ"
            isRequired
          />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose(ModalType.Add);
            }}
          >
            Hủy
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            type="submit"
            isLoading={createLoading || updateLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {qrCodeDetail ? "Cập nhật" : "Tạo"}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
