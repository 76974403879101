// Chakra Icons
import { BellIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import {
  ArgonLogoDark,
  ArgonLogoLight,
  ChakraLogoDark,
  ChakraLogoLight,
  ProfileIcon,
  SettingsIcon,
} from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import { useUserState } from "context/UserContext";
import React, { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import routes from "routes.js";
import { useUserDispatch, signOut } from "context/UserContext";
import logo from "assets/svg/logo-2q.svg";
import { getMemberToken } from "utils/authentication";
import useAxios from "axios-hooks";
import { API_ROUTES, ROOT_API } from "utils/constant";
import { IoWallet } from "react-icons/io5";
import { formatNumber } from "utils/helpers";

export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    ...rest
  } = props;

  const { colorMode } = useColorMode();
  const { isAuthenticated, userInfo } = useUserState();
  const userDispatch = useUserDispatch();
  const history = useHistory();
  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  const notificationColor = useColorModeValue("gray.700", "white");
  const mToken = getMemberToken();

  const [, getProfileGuestApi] = useAxios(
    {
      url: `${ROOT_API}${API_ROUTES.GET_PROFILE_GUEST}`,
    },
    {
      manual: true,
    }
  );

  if (secondary) {
    navbarIcon = "white";
  }

  useEffect(() => {
    if (!!mToken) {
      getProfileGuestApi().then((res) => {
        userDispatch({
          type: "SET_USER_INFO",
          payload: { userInfo: res?.data?.data?.data },
        });
      });
    }
  }, [mToken]);

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      {/* <SearchBar me="18px" /> */}
      {isAuthenticated ? (
        <Flex flexDirection="row" alignItems={"center"} mr={3} gap={3}>
          {!!mToken && (
            <Flex alignItems="center" gap={1} textColor="white">
              <IoWallet size={24} />
              <Text>{formatNumber(userInfo?.usdt || 0)}</Text>
            </Flex>
          )}
          <Menu>
            <MenuButton display="flex" flexDirection="row">
              <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              <Text
                display={{ sm: "none", md: "inline-flex" }}
                color={navbarIcon}
              >
                {userInfo?.username}
              </Text>
            </MenuButton>
            <MenuList p="16px 8px" bg={menuBg}>
              <Flex flexDirection="column">
                <MenuItem borderRadius="8px" mb="10px">
                  <ItemContent
                    info={userInfo?.username}
                    boldInfo={"ADMIN"}
                    aSrc={avatar1}
                    additionalComponent={
                      <div onClick={() => signOut(userDispatch, history)}>
                        <Text color={notificationColor}>Log out</Text>
                      </div>
                    }
                  />
                </MenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <NavLink to="/auth/signin">
          <Button
            ms="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="no-effects"
            rightIcon={
              document.documentElement.dir ? (
                ""
              ) : (
                <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              )
            }
            leftIcon={
              document.documentElement.dir ? (
                <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
              ) : (
                ""
              )
            }
          >
            <Text display={{ sm: "none", md: "flex" }}>Sign In</Text>
          </Button>
        </NavLink>
      )}

      <SidebarResponsive
        hamburgerColor={"white"}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            {/* {colorMode === "dark" ? (
              <ArgonLogoLight w="74px" h="27px" />
            ) : (
              <ArgonLogoDark w="74px" h="27px" />
            )}
            <Box
              w="1px"
              h="20px"
              bg={colorMode === "dark" ? "white" : "gray.700"}
            />
            {colorMode === "dark" ? (
              <ChakraLogoLight w="82px" h="21px" />
            ) : (
              <ChakraLogoDark w="82px" h="21px" />
            )} */}
            <img src={logo} />
          </Stack>
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
      {/* <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      /> */}
      {/* <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w="18px" h="18px" />
        </MenuButton>
        <MenuList p="16px 8px" bg={menuBg}>
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="13 minutes ago"
                info="from Alicia"
                boldInfo="New Message"
                aName="Alicia"
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="2 days ago"
                info="by Josh Henry"
                boldInfo="New Album"
                aName="Josh Henry"
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem borderRadius="8px">
              <ItemContent
                time="3 days ago"
                info="Payment succesfully completed!"
                boldInfo=""
                aName="Kara"
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}
    </Flex>
  );
}
