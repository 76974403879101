import React from "react";
import {
  Button,
  Flex,
  IconButton,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { formatDate, formatNumber } from "utils/helpers";
import DetailMemberDialog from "./DetailMemberDialog";
import {
  API_ROUTES,
  ROOT_API,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from "utils/constant";
import { axiosPost } from "utils/api";
import { DeleteIcon } from "@chakra-ui/icons";

function Row({ history, isLast, fetchData }) {
  const toast = useToast();
  const linkColor = useColorModeValue("blue.500", "white");
  const redColor = useColorModeValue("red.500", "white");
  const greenColor = useColorModeValue("green.500", "white");
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onConfirmTransfer = async () => {
    if (
      !window.confirm(
        "Bạn có chắc chắn muốn xác nhận đã chuyển USDT/VND cho đơn này không?"
      )
    ) {
      return false;
    }
    const data = {
      transactionId: history._id,
    };
    const apiUrl = `${ROOT_API}${API_ROUTES.CONFIRM_TRANSFER}`;
    try {
      const response = await axiosPost(apiUrl, data);
      if (response?.data?.code == 0) {
        toast({
          title: "Xác Nhận Giao Dịch Thành Công",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchData();
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Xác Nhận Giao Dịch Thất Bại",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClick = async () => {
    if (window.confirm("Bạn có chắc chắn muốn hủy giao dịch này không ?")) {
      const data = {
        transactionId: history._id,
        status: TRANSACTION_STATUS.REJECTED,
      };
      try {
        const apiUrl = `${ROOT_API}${API_ROUTES.REJECT_TRANSACTION}`;
        const response = await axiosPost(apiUrl, data);
        if (response?.data?.code == 0) {
          toast({
            title: "Reject Transaction Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Reject Transaction Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  return (
    <>
      <Tr>
        <Td
          width="100px"
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              noOfLines={2}
              maxWidth={{ sm: "150px" }}
            >
              {history?.order_no}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text
            fontSize="md"
            color={linkColor}
            fontWeight="bold"
            noOfLines={2}
            cursor="pointer"
            onClick={() => onOpen(true)}
          >
            {history?.username || ""}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              noOfLines={2}
            >
              {history?.type || ""}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              noOfLines={2}
            >
              {history?.qrCode
                ? history?.qrCode?.code + " - " + history?.qrCode?.standard
                : "-"}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {history?.type === TRANSACTION_TYPE.BUY_COIN
                ? formatNumber(history?.srcAmount || 0)
                : formatNumber(history?.amount || 0)}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {history?.type === TRANSACTION_TYPE.BUY_COIN
                ? formatNumber(history?.amount || 0)
                : formatNumber(history?.srcAmount || 0)}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {formatNumber(history?.ratio || 0)}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth={{ sm: "150px" }}
            >
              {history?.status}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth={{ sm: "150px" }}
            >
              {history?.approvedBy}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text
              fontSize="md"
              color={history?.isTransfer ? greenColor : redColor}
              fontWeight="bold"
              minWidth={{ sm: "150px" }}
              cursor={
                history?.isTransfer ||
                history?.status === TRANSACTION_STATUS.REJECTED
                  ? "default"
                  : "pointer"
              }
              onClick={() =>
                !history?.isTransfer &&
                history?.status !== TRANSACTION_STATUS.REJECTED &&
                onConfirmTransfer()
              }
            >
              {history?.status === TRANSACTION_STATUS.REJECTED
                ? "ĐÃ HỦY"
                : history?.isTransfer
                ? "ĐÃ CHUYỂN"
                : "BẤM VÀO ĐỂ XÁC NHẬN"}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "100px" }}
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth={{ sm: "100px" }}
            >
              {formatDate(history?.createdAt, "DD-MM-YYYY HH:mm:ss")}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "100px" }}
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex direction="column">
            <IconButton
              p="0px"
              bg="transparent"
              variant="no-effects"
              onClick={handleDeleteClick}
            >
              <DeleteIcon />
            </IconButton>
          </Flex>
        </Td>
      </Tr>
      <DetailMemberDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        username={history.username}
      />
    </>
  );
}

export default Row;
