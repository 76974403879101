import {
  Button,
  Flex,
  Image,
  Switch,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { formatNumber } from "utils/helpers";

function BankRow({ row, isLast }) {
  const history = useHistory();
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            {row?.logo ? (
              <Image src={row.logo} w="100px" h="100px" objectFit="contain" />
            ) : (
              <Image
                src="gibbresh.png"
                fallbackSrc="https://placehold.co/50?text=image&font=roboto"
              />
            )}
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.name}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.bin}
            </Text>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default BankRow;
